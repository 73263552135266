$theme-colors: (
        primary: #FFFFFF,
        secondary: #CC3333,
        light: #f3f3f3,
        dark: #4a4a49,
        info: #ccc,
        light-gray: #F2F1F1,
        success: #28a745,
        warning: #ffc107,
        danger: #dc3545,
        blue-dark: #394a77,
        blue-light: #8dbbdb,
        mv-green: #9dc349,
        bb-blue-dark: #394a77,
        box-gray: rgba(0,0,0,.125)
);

$transition-speed: 250ms;
$transition-speed-slow: 500ms;

$carousel-control-color: #000;
$carousel-indicator-active-bg: #000;

// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true !default;
$enable-shadows:            true;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       true;

// Variables
//
// Colors

$body-bg: white;
$body-color: #333;

$navbar-bg : map-get($theme-colors, 'light');
$navbar-border-color : map-get($theme-colors, 'info');


$body-color-inverse: invert($body-color) !default;
$link-color: map-get($theme-colors, 'secondary');
$custom-file-button-color: map-get($theme-colors, 'secondary');
$custom-file-button-bg: #FFF;
$custom-file-border-after-color: map-get($theme-colors, 'secondary');


// Fonts
//$font-family-base: 'Roboto', Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-size-base: 0.875rem;
$line-height-base: 1.75;
$lead-font-size: 1rem;
$lead-font-weight: 400;
$small-font-size: 90%;
$font-family-base: 'Open Sans', "Helvetica Neue", Arial, sans-serif;

$nav-tabs-link-active-color: map-get($theme-colors, 'secondary');
$nav-tabs-link-color: #111;

$headings-font-weight: 400;
$font-weight-bolder: 700;

$headings-font-family: $font-family-base;
$display-font-family: $font-family-base;

$font-weight: 400;
$font-weight-normal: $font-weight;
$font-weight-base: $font-weight;

$btn-font-weight: 400;

$navbar-light-color: map-get($theme-colors, "dark");

$spacer: 1.5rem;

// Hero Banner
$hero-min-height: 350px;
