@import 'variables';

$fa-font-display: swap;

@import 'font-awesome/scss/font-awesome.scss';

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

.border-transparent {
  border: 1px solid transparent;
}

.NgxEditor__Content {
  height: 300px;
  overflow: auto;
}

body {
    padding-top: 65px;
    margin: 0;
    overflow-y: auto;
    max-width: 100%;
}

html, body, .wrapper {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

@import "bootstrap/scss/bootstrap";

.navbar {
  z-index: 120;
}

.navbar-light {
  background-color: $navbar-bg;
}

.nav-tabs {
  .nav-link {
    color: $body-color;
  }
}

.header {
  border-bottom: 1px solid $navbar-border-color;
}

.footer {
  border-top: 1px solid $navbar-border-color;
}

span.fa {
  min-height: 8px;
}

body {
/*
  @media (max-width: 740px) {
    font-size: 12px;

    h1 {
      font-size: 1.5rem;
    }
  }
 */

  h1 {
    font-size: 1.75rem;
    @media (max-width: 378px) {
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  h2 {
    font-size: 1.53rem;
    line-height: 1.25;
    @media (max-width: 378px) {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  h3 {
    font-size: 1.25rem;
  }
}

/* job form multiselect component */
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  max-width: inherit !important;
}

/* Custom list style types */
ul {
  position: relative;

  li {
    list-style-type: none;
  }

  li:before {
    content: '»';
    position: absolute;
    margin: 0 0 0 -20px;
  }
}

ul.no-style,
.card ul,
ul.nav {
  list-style-type: none;

  li:before {
    display: none;
  }
}

select.invalid,
div.invalid,
textarea.invalid,
input.invalid {
  border: 1px solid map-get($theme-colors, 'secondary') !important
}

a.disabled {
  color: map-get($theme-colors, 'info');
}

// chatbot progress bar
.progress {
  border-radius: 0;
}

// content loading indicators
@-webkit-keyframes Gradient {
  0% {
    background-position: 0 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0 50%
  }
}

/* ngx datepicker */
.bs-datepicker-head,
.bs-datepicker-head, .bs-datepicker button:active,
.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover
{
  background-color: map-get($theme-colors, 'secondary') !important;
}

.bs-datepicker-body table td.week span
{
  color: map-get($theme-colors, 'secondary') !important;
}

.jobdetail {
  p {
    margin-bottom: 0;
  }

  strong, b {
    font-weight: 700;
  }

  h3 {
    font-size: 1rem !important;
  }

  &.loading {
    min-height: 500px;
  }
}

/* misc */
.w-33 {
  width: 33.3%;
}

// modals
.company-select-modal {
  padding: 50px 0;

  h1 {
    color: map-get($theme-colors, "blue-dark");
  }

  .buttons {
    button {
      margin: 0.5rem 0;
    }
  }
}
.statemodal {
  text-align: center;
  padding: 50px 0;

  h1 {
    color: map-get($theme-colors, "blue-dark");
  }

  .buttons {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 90%;

    @media (min-width: 576px) {
      width: 325px;
    }

    button {
      margin: 0.5rem 0;
      border: 1px solid transparent;
    }

    .mv {
      background: map-get($theme-colors, "mv-green") linear-gradient(180deg, rgba(157, 195, 73, 1) 0%, rgba(104, 136, 56, 1) 100%);
    }

    .bb {
      background: map-get($theme-colors, "bb-blue-dark") linear-gradient(180deg, rgba(57, 96, 131, 1) 0%, rgba(57, 74, 119, 1) 100%);
    }

    .mv:hover {
      background: #FFF;
      color: map-get($theme-colors, "blue-dark");
      border: 1px solid map-get($theme-colors, "mv-green");
    }

    .mv:focus {
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(57, 74, 119, 0.5) !important;
    }

    .bb:hover {
      background: #FFF;
      color: map-get($theme-colors, "blue-dark");
      border: 1px solid map-get($theme-colors, "bb-blue-dark");
    }

    .bb:focus {
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(57, 74, 119, 0.5) !important;
    }
  }
}

.alert-custom {
  color: #4a4a49;
  background-color: #f3f3f3;
  border: 1px solid #394a77;
}

// TODO: make navbar importable and use component in state select component header on mobile
.navbar {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.navbar-light {
  .navbar-toggler {
    padding: 0 0.5rem;
    outline: none;
    transition : border $transition-speed ease;
  }

  .navbar-toggler,
  .navbar-toggler:active,
  .navbar-toggler:focus {
    border-color: transparent;
  }

  .navbar-toggler:hover {
    border-color: rgba(0, 0, 0, 0.1);
  }
}

.navbar-brand {
  line-height: 1.5;
  padding-bottom: 0;
  padding-top: 0;
  margin-right: 0.5rem;
}

@media screen and (max-width: 450px) {
  .navbar {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .navbar-brand {
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 375px) {
  .navbar-brand {
    margin: 0;

    img {
      width: 120px;
      height: 40px;
    }
  }

  .btn {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  button.ml-2 {
    margin-left: 0.25rem !important;
  }
}

@media screen and (max-width: 325px) {
  .navbar-brand {
    img {
      width: 115px;
    }
  }

  .btn {
    margin-left: 0;
    margin-right: 0;
  }

  button.ml-2 {
    margin-left: 0.1rem !important;
  }
}

.primaryNavigation {
  .nav-link {
    color: $navbar-light-color;
  }
  .nav-link:hover {
    text-decoration: underline;
  }
}

.red-uppercase-text {
  text-transform: uppercase;
  color: map-get($theme-colors, 'secondary');
}

.green-uppercase-text {
  text-transform: uppercase;
  color: #588929;
}

.blue-uppercase-text {
  text-transform: uppercase;
  color: #4D738F;
}

.carousel-control {
  cursor: pointer;
}

.carousel-control-prev {
  left: -45px;
}

.carousel-control-next {
  right: -45px;
}

@media (min-width: 576px) {
  .carousel-control-prev {
    left: -75px;
  }

  .carousel-control-next {
    right: -75px;
  }
}

@media (min-width: 992px) {
   .carousel-control-prev {
     left: -150px;
   }
   .carousel-control-next {
     right: -150px;
   }
 }

.broadcastarea-row .carousel-indicators {
  bottom: -75px;
}

.feedback-row .carousel-indicators {
  bottom: -50px;
}
